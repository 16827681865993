body {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
.font-smallest {
    font-size: 12px;
}
.font-small {
    font-size: 14px;
}
.font-normal {
    font-size: 16px;
}
.font-large {
    font-size: 18px;
}
.font-extraLarge {
    font-size: 21px;
}
.font-bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}
.font-uppercase {
    text-transform: uppercase;
}
.font-highlighted {
    color: var(--highlighted-text-color);
}
.font-dark-blue {
    color: var(--dark-blue);
}
.font-light-blue {
    color: var(--light-blue);
}
.font-orange {
    color: var(--orange);
}
.font-weight-600 {
    font-weight: 600;
}
.font-footer-text{
    font-weight: 300;
}