.page.trusted-by-page {
    background: var(--lightest-blue);
    min-height: unset;
    padding: 1em;
    .partner-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        img {
            margin: 8px;
        }
    }
}