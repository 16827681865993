@media (max-width: 1500px) {
    .app-header{
        height: auto;
        .welcome-root {
            margin-top: 100px;
            .col-right{
                padding-bottom: 2em;
            }
        }
    }
    
}

@media screen and (orientation:portrait) {
    .btn {
        height: 2.5em;
    }
    .app-header {
        height: auto;
        .top-bar {
            padding: 1em 12px;
            flex-direction: column;
            .img-container {
                height: 40px;
                width: 141px;
            }
            .nav-elements {
                justify-content: center;
                .nav-element {
                    margin: 3px;
                }
                .btn {
                    margin-top: 16px;
                }
            }
        }
        .welcome-root {
            height: auto;
            .col-left {
                padding: 155px 12px 12px 12px;
            }
            .col-right {
                padding: 12px 12px 36px 12px;
                min-width: unset;
                width: 100%;
            }
        }
    }
    .page.intro-page {
        padding-left: 20px;
        padding-right: 20px;
        .image-left{
            display: none;
        }

        .row {
            margin-top: 1em;
            padding: 0;
            .col-left{
                margin-right: 0;
                text-align: center;
                
            }
            .col-right {
                width: 100%;
                padding: 12px;
                justify-content: flex-start;
                display: none;
            }
            
        }
    }
    .page.exp-page {
        padding: 12px;
        flex-direction: column;
        justify-content: flex-start;
        .col-left {
            .text {
                width: 100%;
            }
        }
        .col-right {
            width: 100%;
            .info-box-root {
                position: sticky;
            }
        }
    }
    .page.products-page {
        .card-container {
            .product-card {
                width: 100%;
                max-width: 400px;
                margin: 12px 20px 36px 20px;
                padding: 3em 1em;
            }
        }
    }
    .page.faq-page {
        padding: 12px;
        .background-container {
            background: none;
            width: 100%;
            margin: 30px auto;
            flex-direction: column;
            justify-content: flex-start;
            height: auto;
            .inner-background {
                display: none;
            }
            .inner-circle {
                display: none;
            }
            .info-box-root {
                position: unset;
                width: 100%;
                margin-bottom: 3em;
                flex-direction: row !important;
                transform: unset !important;
                .dot {
                    display: none;
                }
                .text-container {
                    padding: 12px;
                }
            }
            .center-logo {
               display: none;
            }
        }
        .faq-container {
            .row {
                width: 100%;
                .accordion-root {
                    width: 90%;
                }
            }
            .qmarks-img {
                height: 250px;
            }
        }
        .progress-container {
            margin: 0;
            .progress-bar {
                left: 42px;
                z-index: 0;
                height: 890px;
                top: 20px;
            }
            .info-box-root {
                z-index: 1;
                width: 100%;
                position: unset;
                flex-direction: row !important;
                margin: 0 !important;
                height: 200px;
                .text-container {
                    padding-left: 12px;
                }
                .dot {
                    display: none;
                }
            }
            .percent-container {
                top: -20px;
                left: 24px;
                margin: 0;
                width: 100%;
                .msg-box {
                    width: 240px;
                    padding: 6px 18px;
                }
            }
        }
        .viva-container {
            .row {
                margin-bottom: 2em;
            }
            .block-container {
                margin-bottom: 3em;
            }
            .block {
                margin-bottom: 0.5em;
            }
            .viva-logo-container {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        } 
    }
    .page.footer-page {
        padding: 12px;
        .contacts {
            flex-wrap: wrap;
            
            .center{
                    order: 0;
            }
            
        }
        .navigation {
            flex-wrap: wrap;
            .nav-element:first-child, .nav-element:last-child {
                padding: 1em;
            }
        }
    }
}