html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: var(--main-bg-color);
  color: var(--main-text-color);
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
main {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.hidden {
  opacity: 0;
}
a {
  text-decoration: none;
  color: var(--main-text-color);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.animated {
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
  animation-fill-mode: none;
  -webkit-animation-fill-mode: none;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes rotate {
  0% {
      /*transform: scale(1);*/
      transform-origin: center center;
      transform: rotate(-360deg) scale(1);
  }
  50% {
      /*transform: scale(1.1);*/
      transform-origin: center center;
      transform: rotate(-180deg) scale(0.70);
  }
  100% {
      /*transform: scale(1);*/
      transform-origin: center center;
      transform: rotate(0) scale(1);
  }
}
@keyframes rotate2 {
  0% {
      /*transform: scale(1);*/
      transform-origin: center center;
      transform: rotate(-360deg);
  }
  50% {
      /*transform: scale(1.1);*/
      transform-origin: center center;
      transform: rotate(-180deg);
  }
  100% {
      /*transform: scale(1);*/
      transform-origin: center center;
      transform: rotate(0);
  }
}
@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
.bounce-top {
	animation: bounce-top 0.9s both;
}
.rotate {
  animation-name: rotate;
  -webkit-animation-name: rotate;
}
.rotate2 {
  animation-name: rotate2;
  -webkit-animation-name: rotate2;
  animation-duration: 4s;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.appear-quick {
  animation: 0.3s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
.appear {
  animation: 1s appear forwards;
}
.appear-slow {
  animation: 2s appear forwards;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes puff-in-hor {
  0% {
    transform: scaleX(2);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes bg-pan-left {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes float {
	0% {
		//box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		//box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		//box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
@keyframes float2 {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
.animation-delay1 {
  animation-delay: 1s;
}
.animation-delay2 {
  animation-delay: 2s;
}
.animation-delay3 {
  animation-delay: 3s;
}
.animation-delay4 {
  animation-delay: 4s;
}
.floating-animation {
  animation: float 6s ease-in-out infinite;
}
.floating-animation-2 {
  animation-name: float2;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.bg-pan-left {
	animation: bg-pan-left 60s infinite;
}
.puff-in-hor {
	animation: puff-in-hor 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}
.slide-in-right {
	animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-left {
	animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
textarea:focus,
input:focus {
  outline: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
table {
  text-align: left;
  position: relative;
  border-collapse: collapse; 
}
th, td {
  padding: 0.25em 0.75em;
}
td {
  overflow:hidden; 
  white-space:nowrap;
}
thead {
  font-weight: bold;
}
th {
  position: sticky;
  overflow: hidden; 
  white-space:nowrap;
  top: 0; /* Don't forget this, required for the stickiness */
}
textarea {
  width: 100%;
  height: 136px;
  padding: 26px 12px 10px 12px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  resize: none;
  color: var(--main-text-color);
}
.drop-shadow-type1 {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
}
input {
  color: var(--main-text-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.m-05 {
  margin: 0.5em;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.mt-05 {
  margin-top: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mr-05 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.ml-05 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mx-05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.mx-1 {
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}
.my-05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.m-05 {
  margin: 0.5em;
}
.p-05 {
  padding: 0.5em;
}
.p-1 {
  padding: 1em;
}
.p-2 {
  padding: 2em;
}
.pt-05 {
  padding-top: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pr-05 {
  padding-right: 0.5em;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2 {
  padding-right: 2em;
}
.pl-05 {
  padding-left: 0.5em;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pb-05 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.px-05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}
.py-05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}