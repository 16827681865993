.page.faq-page {
    position: relative;
    padding: 0 3em;
    .background-container {
        width: 600px;
        height: 600px;
        margin: 40px auto;
        border-radius: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .inner-background {
            background: var(--blue-gradient);
            width: 100%;
            height: 100%;
            border-radius: 100%;
            position: absolute;
            z-index: 1;
        }
        .inner-circle {
            width: 530px;
            height: 530px;
            background: #fff;
            border-radius: 100%;
            z-index: 2;
        }
        .center-logo {
            position: absolute;
            z-index: 2;
        }
    }
    .info-box-root {
        position: absolute;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 395px;
        z-index: 2;
        transition: 0.3s;
        &:hover { 
            transform: scale(1.05);
            transition: 0.3s;
        }
        .text-container {
            padding: 30px 38px;
            width: calc(100% - 100px);
        }
        .small-circle {
            width: 100px;
            height: 100px;
            box-shadow: 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25);
            background: #fff;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 90px;
            }
        }
        .dot {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            &.lightish-blue {
                background: var(--lightish-blue)
            }
            &.light-blue {
                background: var(--light-blue)
            }
            &.dark-blue {
                background: var(--dark-blue)
            }
            &.black {
                background: #000;
            }
            &.orange {
                background: var(--orange)
            }
        }
    }
    .faq-container {
        margin-top: 190px;
    }
    .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .progress-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 3em 0;
        .progress-bar {
            height: 800px;
            width: 15px;
            background: var(--blue-gradient);
            position: absolute;
        }
        .info-box-root {
            position: relative;
        }
        .percent-container {
            display: flex;
            margin-left: 385px;
            z-index: 6;
            position: relative;
            top: 40px;
            .circle {
                width: 50px;
                height: 50px;
                box-shadow: 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25);
                background: #fff;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .msg-box {
                margin-left: 10px;
                width: 375px;
                border-radius: 50px;
                box-shadow: 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25), 0px 3.14345px 8.64449px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                padding: 0.5em 1em;
                padding-left: 1.5em;
                
            }
        }
    }
    .viva-container {
        margin-top: 100px;
        .row {
            margin-bottom: 6em;
        }
        .block-container {
            width: 350px;
        }
        .block {
            display: flex;
            font-style: italic;
            align-items: flex-start;
            opacity: 0.7;
            margin-bottom: 8em;
            .number {
                font-size: 50px;
                color: var(--light-blue);
                margin-right: 4px;
            }
        }
        .img-block {
            text-align: center;
            padding: 0 40px;
            .img-container {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
        .viva-logo-container {
            margin-bottom: 4em;
            img {
                display: block;
                margin: auto;
            }
        }
        .isv-info{
            text-align: center;
            padding-bottom: 2em;
            opacity: 0.7;
            font-size: 12px;
        }
    }
}