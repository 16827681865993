.page.products-page {
    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .product-card {
            width: 420px;
            height: 560px;
            border-radius: 20px;
            margin: 1em 2em 5em 0;
            padding: 3em;
            cursor: pointer;
            position: relative;
            .icon-container {
                height: 96px;
                width: 96px;
                background: var(--blue-gradient2);
                margin: auto;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn {
                position: absolute;
                bottom: -26px;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
            }
            &:hover {
                background: var(--blue-gradient3);
                transition: 0.7s;
                color: #fff;
                
                .icon-container {
                    background: #fff;
                }
            }
        }
    }
}