.btn {
    user-select: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-btn-bg-color);
    color: var(--primary-btn-color);
    border-radius: 8px;
    height: 3.5em;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    padding: 0 16px;
    font-family: Poppins;
    //min-width: 100px;
    position: relative;
    text-transform: uppercase;
    min-width: 180px;
    transition: all 0.7s;
    .img-container {
        height: 3.5em;
        margin: 0 4px;
        img {
            height: 100%;
        }
    }
    &:hover {
        background: var(--primary-btn-bg-color-hover);
        transform: scale(1.05);
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
    }
}