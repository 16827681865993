.app-header {
    background: var(--blue-gradient);
    color: #fff;
    position: relative;
    width: 100%;
    height: 960px;
    overflow: hidden;
    .app-header-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        z-index: 0;
    }
    .top-bar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 2em 3em;
        width: 100%;
        max-width: 1920px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        .img-container {
            height: 80px;
            width: 282px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .nav-elements {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            .nav-element {
                margin: 0.5em 1em;
                padding: 6px;
                cursor: pointer;
                a {
                    color: #fff;
                }
                &.selected {
                    border-bottom: 3px solid #fff;
                    padding-bottom: 3px;
                }
                &.btn {
                    a {
                        color: var(--dark-blue);
                    }
                    background: #fff;
                }
            }
        }
    }
    .welcome-root {
        width: 100%;
        height: 100%;
        max-width: 1920px;
        margin: auto;
        position: relative;
        z-index: 4;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h1 {
            font-size: 46px;
        }
        .welcome-text {
            max-width: 400px;
        }
        .col {
            padding: 0 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .row {
                margin: 2em 0;
                display: flex;
                justify-content: space-between;
                .contacts-link {
                    display: flex;
                    align-items: center;
                    border-bottom: 3px solid #fff;
                    padding: 0 12px;
                    cursor: pointer;
                    .text {
                        a {
                            color: #fff;
                        }
                    }
                    .icon {
                        height: 21px;
                        img {
                            height: 100%;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
        .col-right {
            min-width: 800px;
            align-items: flex-end;
            padding-right: 120px;
            .box-row-left {
                align-self: start;
            }
        }
        .col-left {
            padding-left: 120px;
        }
        .info-box {
            background: #fff;
            color: #000;
            border-radius: 20px;
            padding: 1.5em 1em;
            margin: 1em;
            max-width: 400px;
            .info-box-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text {
                    max-width: 270px;
                }
            }
        }
    }
    .shape {
        position: absolute;
        bottom: -595vw;
        left: -250vw;
        width: 600vw;
        height: 600vw;
        border-radius: 100%;
        background: #fff;
    }
}