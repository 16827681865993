.accordion-root {
    background: #fff;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.15);
    border-radius: 45px;
    display: inline-flex;
    flex-direction: column;
    margin: 1em;
    width: 550px;
    transition: border-radius 0.3s;
    .accordion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 1em 2em;
        user-select: none;
        font-weight: 600;
        .accordion-header-icon {
            transition: 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
        }
    }
    .accordion-content-text {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s, padding 0.5s, opacity 0.1s;
        opacity: 0;
    }
    &.open {
        border-radius: 35px;
        transition: border-radius 0.3s;
        .accordion-header {
            .accordion-header-icon {
                transform: rotate(180deg)
            }
        }
        .accordion-content-text {
            transition: max-height 0.3s, padding 0.1s, opacity 1s;
            max-height: 2000px;
            padding: 2em;
            opacity: 1;
        }
    }
}