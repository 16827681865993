:root {
    --main-text-color: #000;
    --highlighted-text-color: #F54D1F;
    --main-bg-color: #fff;

    --primary-btn-bg-color: #F54D1F;
    --primary-btn-bg-color-hover: #05D3EF;;
    --primary-btn-color: #fff;

    --blue-gradient: linear-gradient(96.49deg, #1E079B 2.45%, #05D6F0 96.13%);
    --blue-gradient2: linear-gradient(132.82deg, rgba(31, 0, 152, 0.6) 12.4%, rgba(0, 195, 255, 0.6) 61.77%);
    --blue-gradient3: linear-gradient(146.07deg, rgba(2, 116, 233, 0.8) 17.57%, rgba(5, 211, 239, 0.8) 79.85%);
    --lightest-blue: #F3F8FF;
    --lightish-blue: #1FCAFF;
    --light-blue: #05D3EF;
    --dark-blue: #1648B5;
    --orange: #F54D1F;
}