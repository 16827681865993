.page.intro-page {
    .title {
        text-align: center;
        max-width: 450px;
        margin: auto;
    }
    p {
        max-width: 700px;
        margin: 1em auto;
        text-align: center;
        margin-bottom: 2em;
    }
    .row {
        display: flex;
        padding: 0 3em;
        margin: 3em 0 2em;
        justify-content: center;
        .col {
            position: relative;
            height: 500px;
            width: 400px;
            .img-container {
                z-index: 5;
                position: absolute;
                width: 100%;
                height: 60%;
                top: 50%;
                right: -60px;
                transform: translateY(-50%);
                img {
                    height: 100%;
                }
            }
            .rect {
                z-index: 4;
                position: absolute;
                height: 100%;
                width: 400px;
                background: var(--blue-gradient2);
                border-radius: 30px;
            }
        }
        .col-right {
            padding-left: calc(60px + 3em);
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            p {
                text-align: left;
            }
            .img-container {
                right: 60px;
            }
        }
        .col-left {
            margin-right: 150px;
        }
    }
}