.page.footer-page {
    min-height: unset;
    background: var(--blue-gradient);
    position: relative;
    padding: 2em;
    color: #fff;
    text-align: center;
    width: 100%;
    .app-footer-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        z-index: 0;
    }
    .img-container {
       display: flex;
       justify-content: center;
       margin-top: 25%;
    }
    .contacts {
        display: flex;
        justify-content: center;
        position: relative;
        
        .contact-box {
            margin: 1em 2em;
            order: 1;
            .text{
                line-height: 1.3;
                .mailto{
                    color: #fff;
                }
            }
            .content {
                text-align: center;
                padding: 0.75em;
                
                .header {
                    margin-bottom: 0.75em;
                }
            }
            
            
        }
    }
    .navigation {
        display: flex;
        justify-content: center;
        margin-bottom: 3em;
        z-index: 5;
        position: relative;
        .nav-element {
            padding: 1em;
            border-bottom: 2px solid #fff;
            cursor: pointer;
            a {
                color: #fff;
            }
            &:first-child {
                padding-left: 5em;
            }
            &:last-child {
                padding-right: 5em;
            }
        }
    }
}