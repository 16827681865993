.page.exp-page {
    display: flex;
    justify-content: center;
    padding: 0 5em;
    position: relative;
    overflow: hidden;
    min-height: 60%;
    .shape {
        position: absolute;
        bottom: 0;
        left: -200vw;
        width: 500vw;
        height: 500vw;
        border-radius: 100%;
        background: var(--lightest-blue);
        z-index: 1;
    }
    .col {
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .col-left {
        position: relative;
        justify-content: flex-start;
        padding-top: 120px;
        .text {
            width: 480px;
        }
    }
    .col-right {
        width: 500px;
        position: relative;
        .info-box-root {
            height: 160px;
            width: 160px;
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            margin: 1em;
            position: absolute;
            &.highlighted {
                background: var(--light-blue);
                color: #fff;
            }
            .info-box-header {

            }
            .info-box-content {
                width: 130px;
            }
        }
    }
}